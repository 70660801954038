import Network from '@/services/network'
import {ref} from 'vue'


export default function handleCostCentres(){
    const network = new Network;
    const loading = ref(false)

    const fetchCostCentreList = (query) => {
        return network.api('get', `/procurement/organization-setting/cost-centre` + query);
    }

    return {
        fetchCostCentreList,
        loading
    }
}