import Network from '@/services/network'

export default function handleImportTracker() {
    const network = new Network;

    const fetchContacts = (query) => {
        return network.api('get', '/company/users/fetch-users' + query);
    }

    const fetchImportTracker = (query) => {
        return network.api('get', '/procurement/import-tracker' + query);
    }
    const storeImportTracker = (data, query) => {
        return network.api('post', '/procurement/import-tracker' + query, data);
    }

    const updateImportTracker = (id, data, query) => {
        return network.api('post', `/procurement/import-tracker/${id}` + query, data);
    }

    const importTrackerStepTypes = {
        pi_receive: "Pi Receive",
        product_readiness: "Product Readiness",
        ip_status: "IP Status",
        apply_for_lc: "Apply for LC",
        lc_opened: "LC Opened",
        lc_payment: "LC Payment",
        noc_shipping_guarantee: "NOC/ Shipping Guarantee",
        product_release_status: "Product Release status",
        vendor_payment_bill: "Vendor Payment / Bill",
        grn: "GRN (Qty)",
        product_costing: "Product Costing"
    }

    return {
        fetchContacts,
        fetchImportTracker,
        storeImportTracker,
        updateImportTracker,
        importTrackerStepTypes
    }
}
