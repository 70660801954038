<template>
    <div class="card p-2" style="min-height: 200px">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th style="width:13%">Date</th>
                        <th style="width:13%">Voucher Type</th>
                        <th>Tnx Type</th>
                        <th>Voucher No</th>
                        <th style="width: 20%;">Memo</th>
                        <th>Created By</th>
                        <th>Pary name</th>
                        <th>Amount</th>
                        <th>Attachments</th>
                        <th style="width:16%">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in journals" :key="i">
                        <td>
                            <span class="fw-bold">{{ item.date }}</span>
                        </td>
                        <td>{{ convertToTitleCase(item.voucher_type) }}</td>
                        <td>{{ convertToTitleCase(item.txn_type) }}</td>
                        <td>{{ item.voucher_no }}</td>
                        <td>{{ item.note }}</td>
                        <td>{{ item.updated_by_user? item.updated_by_user.name : 'N/A' }}</td>
                        <td>{{ item.party ? item.party.name : 'N/A' }}</td>
                       
                        <td> {{ commaFormat(item.total_amount) }} </td>
                        <td>
                          <button v-if="item.attachments.length > 0" class="btn btn-primary btn-sm"
                                  style="cursor: pointer"
                                  @click="viewAttachments(item.attachments)"
                          >
                            <i class="fas fa-eye"></i>
                            View
                          </button>
                          <span v-else class="badge btn-danger">
                            No attachments
                          </span>
                        </td>
                        <td>
                            <button class="btn btn-primary btn-sm"
                                    style="cursor: pointer"
                                    @click="goToEdit(item.id, item.voucher_type)"
                            >
                                <i class="fas fa-eye"></i>
                            </button>
                            <button class="btn btn-secondary btn-sm"
                                    @click="goToPrint(item.id, item.voucher_type)"
                                    style="cursor: pointer; margin-left: 10px"
                            >
                                <i class="fas fa-print"></i>
                            </button>
                        </td>
                    </tr>

                </tbody>
            </table>
            <p v-if="!journals.length" class="text-center mt-5">No data</p>
        </div>

      <ViewAttachment
          ref="viewAttachmentRef"
          :attachments="modalAttachments"
      />
    </div>
</template>

<script setup>
    import figureFormatter from '@/services/utils/figureFormatter'
    import {useRoute, useRouter} from "vue-router";
    import {computed} from "@vue/runtime-core";
    import {useStore} from "vuex";
    import ViewAttachment from '@/views/page/company/ngo/voucher/payment/ViewAttachmentModal.vue'
    import {ref} from 'vue'
    import { convertToTitleCase } from '@/services/utils/global';
    import { useVoucherPrint } from '@/services/utils/voucherPrint';

    
    const $store = useStore();
    const route = useRoute();
    const router = useRouter();
    const journals = computed(() => $store.getters["journal/getJournals"])
    const {commaFormat} = figureFormatter()
    const modalAttachments = ref([]);
    const viewAttachmentRef = ref();
    
    const { printVoucher, vouchers } = useVoucherPrint();

    const goToPrint = (id, type) => {
        printVoucher(id, type);
    }

    const redirectToPrint = (id) =>{
        let param = {
            companyId: route.params.companyId,
            moduleId: route.params.moduleId,
            menuId: route.params.menuId,
            pageId: route.params.pageId,
            journalId: id,
        }
        router.push({
            name: 'ngo-journal-voucher-print',
            params: param,
            query: route.query
        })
    }



    const goToEdit = (id, type) => {
        const idParam = vouchers[type].idParams;
        const routeName = vouchers[type].route;
        router.push({
            name  : routeName,
            params: {
                companyId: route.params.companyId,
                moduleId : route.params.moduleId,
                menuId   : route.params.menuId,
                pageId   : route.params.pageId,
                [idParam]: id
            },
            query : {
                start: route.query.start,
                end: route.query.end,
                type: 'all-vouchers'
            }

        })
    }

    const viewAttachments = (attachments) => {
      modalAttachments.value = [];
      modalAttachments.value = attachments;
      viewAttachmentRef?.value.toggleModal();
    }
</script>


<style scoped>
.table > :not(caption) > * > * {
    padding: 0.72rem 0.75rem !important;
}

.table thead th {
    vertical-align: middle !important;
}
</style>